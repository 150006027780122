<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-tie">
          <v-row>
              <v-col>
                  <v-select :items='months' outlined label="bulan"></v-select>
              </v-col>
              <v-col>
                <v-select outlined label="SKPD"></v-select>
              </v-col>
              <v-col>
                <v-select outlined label="Asisten"></v-select>
              </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-simple-table max-width="200px" class="elevation-1">
                <tbody>
                  <tr>
                    <td>Total Poin Valid</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Total Poin</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Nilai</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="{data:items}"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            @onTableChange="updateTable"
          >
          </crud>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import {months} from '@/utils/lib'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'Instruksi', value:'instruksi', sortable:false, class:'header-index-profile'},
        {text:'SKPD', value:'SKPD', sortable:false, class:'header-index-profile'},
        {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
        {text:'Jenis', value:'jenis', sortable:false, class:'header-index-profile'},
        {text:'Poin', value:'poin', sortable:false, class:'header-index-profile'},
      ],
      items:[{
        no:1, nama_kegiatan:'Test Kegiatan', no_sk:'TS/123003/DSDF-DIKES/DF/DF332Q3', keterangan:'Menajalankan dan memantau test kegiatan'
      }],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{
    months(){
      return months
    }
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
